<template>
  <section>
    <b-row>
      <b-col lg="4">
        <b-card class="text-center">
          <b-avatar class="mb-1" :variant="`light-primary`" size="45">
            <feather-icon size="21" icon="TrendingUpIcon" />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">-</h2>
            <span>RMP Total DDA USD/Kg</span>
          </div>
        </b-card>

        <b-card class="text-center">
          <b-avatar class="mb-1" :variant="`light-primary`" size="45">
            <feather-icon size="21" icon="TrendingUpIcon" />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">-</h2>
            <span>WFE Total Kg</span>
          </div>
        </b-card>
      </b-col>

      <b-col lg="8">
        <b-row>
          <b-col>
            <switch-office
              office_name="Chile"
              :office_index="0"
              rmp_usd_kg="-"
              wfe_kg="-"
              @switchChange="switchChange"
            ></switch-office>
          </b-col>
          <b-col>
            <switch-office
              office_name="USA & Canada"
              :office_index="1"
              rmp_usd_kg="-"
              wfe_kg="-"
              @switchChange="switchChange"
            ></switch-office>
          </b-col>
          <b-col>
            <switch-office
              office_name="Mexico & Caribbean"
              :office_index="2"
              rmp_usd_kg="-"
              wfe_kg="-"
              @switchChange="switchChange"
            ></switch-office>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <switch-office
              office_name="Japan & Korea"
              :office_index="3"
              rmp_usd_kg="-"
              wfe_kg="-"
              @switchChange="switchChange"
            ></switch-office>
          </b-col>

          <b-col>
            <switch-office
              office_name="EU & Middle East"
              :office_index="4"
              rmp_usd_kg="-"
              wfe_kg="-"
              @switchChange="switchChange"
            ></switch-office>
          </b-col>
          <b-col>
            <switch-office
              office_name="China & SEA"
              :office_index="5"
              rmp_usd_kg="-"
              wfe_kg="-"
              @switchChange="switchChange"
            ></switch-office>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <switch-meses
          :dataUrl="demandaIrrestrictaMonthsCategories"
          @meses-change="mesesChange"
        ></switch-meses>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <bruna-element-loader
          :dataUrl="demandaIrrestrictaChart00"
          ref="chart00"
        >
        </bruna-element-loader>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <bruna-element-loader
          :dataUrl="demandaIrrestrictaChart01"
          ref="chart01"
        >
        </bruna-element-loader>
      </b-col>
      <b-col>
        <bruna-element-loader
          :dataUrl="demandaIrrestrictaChart03"
          ref="chart03"
        ></bruna-element-loader>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4">
        <bruna-element-loader
          :dataUrl="demandaIrrestrictaChart04"
          ref="chart04"
        ></bruna-element-loader>
      </b-col>

      <b-col lg="8">
        <bruna-element-loader :dataUrl="demandaIrrestrictaChart05">
        </bruna-element-loader>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <bruna-element-loader
          :dataUrl="demandaIrrestrictaTable00"
          ref="table00"
        ></bruna-element-loader>
      </b-col>

      <b-col lg="6">
        <bruna-element-loader
          :dataUrl="demandaIrrestrictaTable01"
          ref="table00"
        ></bruna-element-loader>
      </b-col>
    </b-row>

    <b-row>
      <b-col> </b-col>
    </b-row>
  </section>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BrunaElementLoader from "@/views/brunacomponents/BrunaElementLoader.vue";
import SwitchOffice from "./SwitchOffice.vue";

import SwitchMeses from "./SwitchMeses.vue";

var qs = require("qs");

import {
  BRow,
  BCol,
  BFormSelect,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover,
} from "bootstrap-vue";

import useApiServices from "@/services/useApiServices.js";

export default {
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },

  props: {
    forecast: {
      type: Object,
    },
  },

  data() {
    return {
      demandaIrrestrictaMonthsCategories:
        useApiServices.demandaIrrestrictaMonthsCategories,

      demandaIrrestrictaChart00: useApiServices.demandaIrrestrictaChart00,
      demandaIrrestrictaChart01: useApiServices.demandaIrrestrictaChart01,
      demandaIrrestrictaChart02: useApiServices.demandaIrrestrictaChart02,
      demandaIrrestrictaChart03: useApiServices.demandaIrrestrictaChart03,
      demandaIrrestrictaChart04: useApiServices.demandaIrrestrictaChart04,

      demandaIrrestrictaChart05: useApiServices.demandaIrrestrictaChart05,

      demandaIrrestrictaTable00: useApiServices.demandaIrrestrictaTable00,
      demandaIrrestrictaTable01: useApiServices.demandaIrrestrictaTable01,
    };
  },
  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    ToastificationContent,
    BCardTitle,
    BCardSubTitle,
    VBPopover,
    VBTooltip,
    BFormRadioGroup,
    BrunaElementLoader,
    BAvatar,
    SwitchOffice,
    SwitchMeses,
  },

  created() {},

  mounted() {},

  methods: {
    switchChange(checked, officeIndex) {
      console.log({ checked, officeIndex });

      this.$refs.chart00.getRef().chart.series[officeIndex].setVisible(checked);
      this.$refs.chart01.getRef().chart.series[officeIndex].setVisible(checked);
    },

    mesesChange(data) {
      console.log(data);

      let query = qs.stringify({ months: data });

      console.log({ query });

      this.demandaIrrestrictaChart00 =
        useApiServices.demandaIrrestrictaChart00 + "?" + query;

      //console.log(this.$refs.chart00.getRef().chart.series)
    },
  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
